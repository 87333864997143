<template>
  <div class="equipment-create-page container" v-if="currentAccount">
    <h1>Create equipment</h1>
    <hr />

    <form @submit.prevent="create">
      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input type="text" class="form-control" id="name" v-model="name" />
      </div>

      <button class="btn btn-primary" :disabled="creating">
        <template v-if="!creating">Create</template>
        <template v-else><i class="fas fa-circle-notch fa-spin"></i></template>
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { createEquipment } from '@/api';

export default {
  name: 'EquipmentCreatePage',
  computed: {
    ...mapGetters(['account/current']),
    currentAccount() {
      return this['account/current'];
    },
  },
  data: () => ({
    name: '',

    creating: false,
  }),
  methods: {
    async create() {
      if (this.creating) {
        return;
      }

      if (!this.name) return this.$toast.error("Name can't be empty.");

      try {
        this.creating = true;

        const response = await createEquipment({
          name: this.name,
        });
        if (response.errors) {
          throw new Error(response.errors[0].message);
          return;
        }

        this.$toast.success('Equipment successful created.', { duration: 2000 });
        setTimeout(() => this.$router.push({ name: 'equipments' }), 2000);
      } catch (err) {
        this.$toast.error(err.message);
        this.creating = false;
      }
    },
  },
  mounted() {
    this.creating = false;
  },
  watch: {
    currentAccount() {
      this.updateEquipment();
    },
  },
};
</script>

<style scoped>
.equipment-create-page {
  padding: 2em 0;
}
</style>
